.column-sort {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  transition: $transition;
  &:hover {
    background: #ddd;
  }
  &__icon {
    margin-right: 5px;
    .icon {
      display: block;
    }
  }
  &__label {}
}

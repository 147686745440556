.switch {
  position: relative;
  display: inline-flex;
  &__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  &__input {
    position: absolute;
    opacity: 0;
    &:checked {
      ~ .switch__check {
        background: $green;
        .switch__check__icon {
          left: 12px;
        }
      }
    }
  }
  &__check {
    background: $medium-gray;
    transition: $transition;
    display: inline-block;
    border-radius: 10px;
    width: 30px;
    height: 20px;
    position: relative;
    &__icon {
      background: white;
      transition: $transition;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
}

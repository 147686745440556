@mixin inputIntent ($color) {
  &::after {
    background: $color;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    pointer-events: none;
  }
}

.input-wrapper {
  position: relative;
  // @include inputIntent($blue-pink-gradient);
  @include e('input') {
    transition: all 200ms;
    border: 1px solid $gray;
    width: 100%;
    background: $gray !important;
    outline: none !important;
    box-shadow: none !important;
    color: $dark-gray;
    font-size: 14px;
    line-height: 1;
    height: 30px;
    padding: 0 8px;
    &[size] {
      width: initial;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
  @include m('small') {
    .input-wrapper__input {
      font-size: 12px;
      height: 30px;
    }
  }
  @include m('large') {
    .input-wrapper__input {
      font-size: 22px;
      height: 50px;
    }
  }
  // @include m('primary') {
  //   @include inputIntent($primary);
  // }
  // @include m('success') {
  //   @include inputIntent($success);
  // }
  // @include m('danger') {
  //   @include inputIntent($danger);
  // }
  // @include m('warning') {
  //   @include inputIntent($warning);
  // }
  // @include m('info') {
  //   @include inputIntent($info);
  // }
}

.input-and-preview {
  position: relative;
  @include m('file') {
    display: flex;
    align-items: flex-start;
  }
  @include m('selected') {
    .input-and-preview__label {
      &::before {
        display: none;
      }
    }
  }
  @include e('current-file') {
    margin-left: 20px;
    p {
      margin-bottom: 5px;
    }
  }
  .form__control__preview {
    margin: 0 10px 0 0;
    border: 1px solid rgba(black, 0.5);
    width: auto;
    height: 50px;
    overflow: hidden;
    padding: 0;
  }
  label {
    position: relative;
    display: inline-block;
  }
  @include e('label') {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $medium-gray;
    color: white;
    padding: 10px 20px 10px 10px;
    font-size: 14px;
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      border: 1px dashed white;
      border-radius: 5px;
    }
  }
  .input-wrapper {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.dropzone {
  text-align: center;
  border: 1px dashed $dark-gray;
  padding: 2px;
  &__wrapper {
    padding: 40px;
    background: $gray;
  }
  &__title {
    font-size: 36px;
    font-weight: $bold-weight;
    color: $dark-gray;
  }
  &__text {
    font-size: 20px;
  }
}

.queue-files {
  margin: 0 0 10px 0;
  &--sending {
    pointer-events: none;
    opacity: 0.5;
  }
}

.queue-file {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray;
  padding: 10px;
  &__name {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin-right: 10px;
  }
  &__size {
    margin-right: 10px;
  }
  &__remove {
    cursor: pointer;
  }
}

.list-filters {
  margin: 0 0 20px 0;
  @include tabletUp {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 20px -10px;
    > * {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 10px;
    }
  }
  @include e('clear-filters') {
    flex-grow: 0;
    flex-basis: auto;
    margin-top: 20px;
  }
}

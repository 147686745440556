.room-relation {
  margin: 0 0 $small-gutter 0;
  padding: 20px;
  border: 1px solid $gray;
  @include e('title') {
    font-weight: $bold-weight;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid $gray;
  }
  @include e('tip') {
    margin: 0 0 20px 0;
  }
  @include e('list') {
    @include e('item') {
      display: flex;
      align-items: center;
      margin-top: 10px;
      @include m('removed') {
        text-decoration: line-through;
        font-style: italic;
        .room-relation__list__item__button,
        .input-wrapper {
          opacity: 0.3;
        }
        .input-wrapper {
          pointer-events: none;
        }
      }
      @include e('text') {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        margin-right: 20px;
        @include e('label') {
          margin-right: 15px;
        }
        @include m('flex') {
          display: flex;
          align-items: center;
        }
      }
      @include e('button') {
        .link {
          color: $red;
        }
      }
    }
  }
  @include e('actions') {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
    @include e('field') {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      margin-right: 20px;
      @include e('label') {
        margin-bottom: 5px;
        display: block;
      }
      .form__control {
        margin: 0;
      }
    }
    @include e('button') {
      align-self: flex-end;
    }
  }
}

.link {
  display: inline-block;
  @include m('small') {
    font-size: 12px;
  }
  @include m('large') {
    font-size: 20px;
  }
  @include m('button') {
    text-decoration: none;
    padding: 1px 5px;
    position: relative;
    &::after {
      transition: $transition;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0%;
      border-radius: 2px;
    }
    &:hover {
      &::after {
        height: 100%;
        background: rgba(white, 0.1);
      }
    }
    &:active {
      transform: scale(0.95);
    }
  }
}

@mixin buttonIntent ($color, $percentage: 20%) {
  background: $color;
  &:hover {
    box-shadow:
      2px 2px 5px rgba(black, 0.5);
    ;
  }
  &:active {
  }
}

.button {
  transition: $transition, transform 50ms;
  display: inline-flex;
  border: 0;
  padding: 0 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  outline: none;
  @include buttonIntent($dark-gray, 10%);
  // &::after {
  //   @include loader();
  //   content: "";
  //   display: none;
  // }
  &[disabled],
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .spinner {
    display: none;
    div {
      background: white;
    }
  }
  @include m('block') {
    width: 100%;
  }
  @include m('small') {
    font-size: 12px;
    padding: 0 30px;
    height: 30px;
    // &::after {
    //   width: 10px;
    //   height: 10px;
    // }
  }
  @include m('large') {
    font-size: 24px;
    padding: 0 40px;
    height: 70px;
    font-weight: $regular-weight;
    // &::after {
    //   width: 20px;
    //   height: 20px;
    // }
  }
  @include m('spinner') {
    .button__text {
      display: none;
    }
    .spinner {
      display: block;
    }
    // &::after {
    //   display: block;
    // }
  }
  @include m('primary') {
    @include buttonIntent($primary, 10%);
  }
  @include m('danger') {
    @include buttonIntent($danger);
  }
  @include m('warning') {
    @include buttonIntent($warning);
  }
  @include m('info') {
    @include buttonIntent($info);
  }
  @include m('success') {
    @include buttonIntent($success);
  }
}

.notification {
  background: $blue;
  position: fixed;
  z-index: 1000;
  top: $header-height-first;
  left: 0;
  right: 0;
  padding: $small-gutter;
  color: white;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 3px 2px rgba(black, 0.1) inset;
  display: flex;
  @include e('button') {
    margin-left: 20px;
    cursor: pointer;
    .icon {
      display: block;
    }
  }
  @include m('error') {
    background: $red;
  }
  @include m('active') {
    pointer-events: initial;
    opacity: 1;
  }
}

.confirmation {
  position: fixed;
  z-index: $confirmation-index;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  @include m('opened') {
    display: flex;
    .confirmation__wrapper {
      animation: showConfirmationWrapper 300ms forwards;
    }
  }
  @include e('wrapper') {
    background: white;
    padding: 40px;
    max-width: 400px;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(black, 0.1);
    opacity: 0;
  }
  @include e('header') {
    font-weight: $bold-weight;
    margin: 0 0 $small-gutter 0;
  }
  @include e('content') {
    margin: 0 0 $small-gutter 0;
  }
  @include e('actions') {
    @include e('button') {
      padding: 5px 20px;
      cursor: pointer;
      @include m('confirm') {
        background: $red;
        color: white;
      }
      @include m('cancel') {
      }
    }
  }
}

@keyframes showConfirmationWrapper {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

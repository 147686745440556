.pagination {
  display: flex;
  justify-content: center;
  margin-top: $small-gutter;
  flex-wrap: wrap;
  li {
    margin: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $bold-weight;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    &.active {
      a {
        background: darken(#eee, 10%);
        pointer-events: none;
      }
    }
    a {
      color: black;
      display: block;
      padding: 5px 15px;
      background: #eee;
      cursor: pointer;
      &:hover {
        background: darken(#eee, 10%);
      }
    }
  }
}
.pagination-info {
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  color: $dark-gray;
  margin-top: 10px;
}

.module-count {
  transition: $transition;
  box-shadow: 0 2px 4px rgba(black, 0.2);
  padding: $small-gutter;
  text-align: center;
  background: $lighter-gray;
  color: black;
  display: block;
  text-decoration: none;
  line-height: 1;
  &:hover {
    transform: scale(1.04);
    .module-count__icon {
      // transform: rotate(180deg);
    }
  }
  @include e('count') {
    font-size: 48px;
    margin-bottom: 20px;
  }
  @include e('title') {
    font-size: 16px;
    font-weight: $light-weight;
    margin-bottom: 30px;
    line-height: 1.4;
  }
  @include e('icon') {
    transition: $transition;
    background: $dark-gray;
    color: white;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: inline-flex;
    font-size: 20px;
    font-weight: $extra-light-weight;
    justify-content: center;
    align-items: center;
  }
}

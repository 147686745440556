.api-services {
  display: grid;
  grid-gap: $small-gutter;
  @include tabletUp {
    grid-template-columns: repeat(3, 1fr);
  }
}

.api-service {
  border: 1px solid $gray;
  padding: $small-gutter;
  background: white;
  display: flex;
  flex-direction: column;
  &__title {
    text-transform: uppercase;
    margin: 0 0 $small-gutter 0;
    display: flex;
    align-items: center;
    .icon {
      background: $gray;
      margin: 0 10px 0 0;
      padding: 10px;
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
  &__info {
    margin: 0 0 $small-gutter 0;
    line-height: 1.5;
    flex-grow: 1;
    &__value {}
    .setting-item {
      margin: 15px 0 0 0;
      .form__control {
        margin: 0;
      }
    }
  }
  &__actions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: $small-gutter;
    text-align: center;
    border-top: 1px solid $gray;
    padding: $small-gutter 0 0 0;
    &--connected {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

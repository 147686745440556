.image-preview {
  cursor: pointer;
  &--opened {
    .image-preview__full {
      pointer-events: initial;
      opacity: 1;
      perspective: 200px;
    }
    .image-preview__full__image {
      animation: showImage 200ms 100ms forwards $easeOutQuad;
    }
  }
  &__image {
    transition: $transition;
    &:hover {
      background: #999;
    }
  }
  &__full {
    position: fixed;
    overflow: hideen;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    pointer-events: none;
    opacity: 0;
    transition: $transition;
    &__image {
      transition: $transition;
      max-width: 100%;
      max-height: 100%;
      opacity: 0;
    }
    &__download {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: black;
      display: flex;
      align-items: center;
      background: rgba(white, 0.2);
      padding: 3px 20px;
      text-transform: uppercase;
      font-size: 12px;
      > span {
        margin-right: 10px;
      }
      .icon {
        width: 20px;
      }
    }
  }
}

@keyframes showImage {
  0% {
    transform: rotateX(5deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.media-library {
  margin: 0 0 20px 0;
  &--uploading {
    pointer-events: none;
    opacity: 0.5;
    position: relative;
    &::after {
      @include loader(black);
      content: "";
      position: absolute;
      top: 50%;
      right: 5%;
    }
  }
  &--invalid {
    .media-library__label {
      color: $red;
    }
    .media-library__content__upload-zone {
      border-color: $red;
    }
  }
  &__label {
    font-size: 12px;
    margin: 0 0 5px 0;
  }
  &__content {
    display: flex;
    align-items: stretch;
    &__upload-library {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      @include tabletUp {
        flex-direction: row;
      }
    }
    &__upload-zone {
      text-align: center;
      border: 1px dashed $medium-gray;
      padding: 20px;
      background: $light-gray;
      flex-grow: 1;
      flex-shrink: 0;
      position: relative;
      &__title {
        margin: 0 0 5px 0;
        font-size: 22px;
        font-weight: 500;
        pointer-events: none;
      }
      &__subtitle {
        color: $medium-gray;
        pointer-events: none;
      }
      &__input-file {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &--drag-over {
        border-color: $dark-gray;
        background: $lighter-gray;
      }
    }
    &__upload {
      flex-shrink: 0;
      margin-right: 20px;
      align-self: center;
      &__image {
        width: 70px;
        height: 70px;
        border: 1px solid $gray;
        padding: 2px;
        overflow: hidden;
        object-fit: cover;
      }
    }
    &__open-library {
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 15px;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
    }
    &__library {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 100;
      background: rgba(0, 0, 0, 0.2);
      padding: $small-gutter;
      overflow-x: auto;
      display: none;
      &--active {
        display: block;
      }
      @include tabletUp {
        padding: $large-gutter;
      }
      &__wrapper {
        background: $white;
        padding: $small-gutter;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
        min-height: 100%;
        @include tabletUp {
          padding: $large-gutter;
        }
      }
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 $small-gutter 0;
        &__title {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 14px;
        }
        &__close {
          cursor: pointer;
        }
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        grid-gap: $small-gutter;
      }
      &__actions {
        margin-top: $small-gutter;
      }
    }
  }
}

.media-library-item {
  position: relative;
  width: 100px;
  height: 100px;
  &__media {
    cursor: pointer;
    img, video {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &__destroy {
    transition: $transition;
    position: absolute;
    top: 6px;
    right: 6px;
    background: $red;
    color: white;
    padding: 5px;
    cursor: pointer;
    @include desktopUp {
      opacity: 0;
    }
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  &__confirm-destroy {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .button {
      font-size: 10px;
      padding: 0;
      display: block;
      width: 100%;
    }
  }
  &:hover {
    .media-library-item__destroy {
      opacity: 1;
    }
  }
  &--deleting {
    .media-library-item__destroy {
      display: none;
    }
  }
}

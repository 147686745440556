.data-table {
  @include m('loading') {
    opacity: 0.3;
    pointer-events: none;
  }
  @include e('table') {
    width: 100%;
    font-size: 14px;
    text-align: left;
    border-collapse: collapse;
    thead {
      @include smallOnly {
        display: none;
      }
      tr {
        th {
          padding: 8px 0 8px 10px;
          background: #eee;
          &:last-child {
            padding-right: 10px;
          }
        }
      }
    }
    tbody {
      tr {
        @include smallOnly {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          border-bottom: 2px solid #999;
        }
        &:hover {
          td {
            background: #eee;
          }
        }
        td {
          border-top: 1px solid #ddd;
          padding: 8px 0 8px 10px;
          @include smallOnly {
            word-break: break-all;
            overflow: hidden;
            &:before {
              content: attr(data-column);
              color: #999;
              font-size: 11px;
              margin: 0 0 5px 0;
              display: block;
            }
          }
          @include tabletUp {
            &[data-column-field='sort'] {
              width: 50px;
            }
            &[data-column-field='image_thumb_url'] {
              width: 50px;
            }
            &[data-column-field='main-actions'] {
              white-space: nowrap;
              width: 1%;
              text-align: right;
            }
          }
        }
      }
    }
    @include e('actions') {
      @include e('button') {
        cursor: pointer;
        color: #aaa;
        width: 20px;
        height: 20px;
        display: inline-block;
        box-sizing: content-box;
        padding: 10px;
        @include tabletUp {
          padding: 0;
          margin: 0 10px 0 0;
        }
        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

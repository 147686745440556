// colors

$dark-blue: #3A4155;
$blue: #4A526A;
$light-blue: #6AB8F1;
$green: #62AA99;
$white: white;
$dark-gray: #272822;
$medium-gray: #676767;
$gray: #c5c5c5;
$light-gray: #f8f9fa;
$lighter-gray: #f4f4f4;
$orange: #FFAC4B;
$dark-orange: #F57C00;
$dark-red: #D32F2F;
$red: #da7a7a;
$light-red: #ffdbdb;
$purple: #755CB9;
$white-70: rgba(white, 0.7);
$white-30: rgba(white, 0.3);
$yellow: #FBC02D;

// gradients

$blue-gradient: linear-gradient(180deg, #6BBDF9, #6AA5D0);
$blue-pink-gradient: linear-gradient(90deg, #77C3F7, #ECC5FD);
$red-orange-gradient: linear-gradient(90deg, $dark-red, $dark-orange);
$dark-blue-gradient: linear-gradient(10deg, darken($dark-blue, 15%), $dark-blue);

// fonts

$base-font: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$base-size: 14px;

// font weights

$extra-light-weight: 200;
$light-weight: 300;
$regular-weight: 400;
$bold-weight: 700;

// sizes

$header-height-first: 62px;
$header-height-second: 45px;
$header-height: $header-height-first + $header-height-second;

// grid

$small-gutter: 20px;
$large-gutter: 30px;

// transitions

$transition: all 200ms;

// intents

$primary: $purple;
$danger: $red;
$warning: $orange;
$success: $green;
$info: $light-blue;

// indexes

$header-index: 100;
$notification-index: 101;
$confirmation-index: 1000;

// sidebar

$sidebar-width: 240px;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  @include e('center') {
    width: 250px;
    @include e('logo') {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}

.app-layout {
  min-height: 100vh;
  @include e('header') {
    position: absolute;
    z-index: $header-index;
    top: 0;
    left: 0;
    right: 0;
  }
  @include e('content') {
    padding: ($header-height-first + $small-gutter) $small-gutter $small-gutter $small-gutter;
    min-height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
  }
  @include e('sidebar') {
    display: none;
    @include desktopUp {
      display: block;
      width: $sidebar-width;
      position: fixed;
      z-index: 2;
      top: $header-height;
      left: 0;
      bottom: 0;
      overflow: auto;
      box-shadow: 3px 2px 5px 0px rgba(black, 0.1);
    }
    .header-menu {
      display: block;
      height: 100%;
      .header-menu__open-nav {
        display: none;
      }
      .header-menu__nav {
        height: 100%;
        background: blue;
        display: flex;
        .header-menu__nav__items {
          flex-grow: 1;
          margin: 0;
          box-shadow: none;
        }
        .header-menu__nav__item {
          .header-menu__nav__item__link {
            text-decoration: none;
          }
        }
      }
    }
  }
  @include m('logged') {
    @include desktopUp {
      padding-top: $header-height;
    }
    .app-layout__content {
      display: block;
      min-height: auto;
      padding: ($header-height + $small-gutter) $small-gutter $small-gutter $small-gutter;
      @include desktopUp {
        padding: 0;
        margin:
          $small-gutter
          $small-gutter
          $small-gutter
          ($sidebar-width + $small-gutter)
        ;
      }
      @include desktopUp {
        margin:
          $large-gutter
          $large-gutter
          $large-gutter
          ($sidebar-width + $large-gutter)
        ;
      }
    }
  }
}

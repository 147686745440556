.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include m('logged') {
    .main-header__header {
      .header-menu {
        display: block;
      }
    }
    .main-header__client {
      display: block;
      .client-logo {
        @include e('image') {
          width: 80px;
          @include tabletUp {
            width: 100px;
          }
        }
      }
    }
    .main-header__second {
      display: flex;
    }
    .main-header__agency {
      border-right: 1px solid $gray;
    }
  }
  a {
    text-decoration: none;
  }
  @include e('first') {
    position: relative;
    z-index: 2;
    background: $light-gray;
    padding: 0 $small-gutter;
    box-shadow: 0px 1px 5px 2px rgba(black, 0.1);
    display: flex;
    height: $header-height-first;
    justify-content: space-between;
    align-items: center;
    @include e('left') {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    @include e('right') {
      text-align: right;
    }
  }
  @include e('second') {
    background: $medium-gray;
    position: relative;
    z-index: 1;
    height: $header-height-second;
    display: none;
  }
  @include e('modules') {
    display: none;
    @include desktopUp {
      display: block;
      width: 240px;
      padding-left: $small-gutter;
      background: $dark-gray;
      display: flex;
      align-items: center;
      color: $gray;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
    }
  }
  @include e('welcome') {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 75px;
    font-size: 12px;
    color: #ddd;
    @include desktopUp {
      padding-left: 30px;
    }
  }
  @include e('agency') {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    // border-right: 1px solid $gray;
    margin-right: 15px;
    @include desktopUp {
      width: 220px;
    }
    @include e('logo') {
      display: flex;
      align-items: center;
    }
    @include e('title') {
      margin-left: 10px;
      display: none;
      @include desktopUp {
        display: inline-flex;
      }
    }
  }
  @include e('client') {
    display: none;
  }
  @include e('header') {
    height: 40px;
    .header-menu {
      display: none;
      @include desktopUp {
      }
    }
  }
  @include e('admin') {
    display: flex;
    align-items: center;
    @include e('name') {
      text-transform: uppercase;
      font-size: 13px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70px;
      @include desktopUp {
        max-width: 200px;
      }
      @include desktopUp {
        max-width: initial;
      }
    }
    @include e('logout') {
      display: block;
      color: #747474;
      font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
    @include e('avatar') {
      margin-left: 15px;
    }
  }
}

.avatar {
  border-radius: 16px;
  width: 32px;
  height: 32px;
  background: $dark-gray;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-logo {
  @include e('image') {
    filter: grayscale(100%);
    img {
      width: 100%;
    }
  }
}

.agency-logo {
  display: inline-flex;
  width: 30px;
  height: 30px;
  align-items: center;
  @include e('image') {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

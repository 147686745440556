.project-list-type {
  margin: 0 0 20px 0;
  &__options {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    &__item {
      border: 1px solid transparent;
      background: #eee;
      cursor: pointer;
      padding: 5px;
      text-align: center;
      &--selected,
      &:hover {
        background: #ccc;
        border-color: $medium-gray;
      }
      &--variation-1 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              width: 35px;
            }
          }
        }
      }
      &--variation-2 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              width: 35px;
              order: 1;
            }
          }
        }
      }
      &--variation-3 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              width: 80px;
            }
          }
        }
      }
      &--variation-4 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              order: 1;
            }
          }
        }
      }
      &--variation-5 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              width: 25px;
              order: 1;
            }
          }
        }
      }
      &--variation-6 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              width: 45px;
            }
          }
        }
      }
      &--variation-7 {
        .project-list-type__options__item__icon {
          span {
            &:nth-child(1) {
              width: 80px;
              order: 1;
            }
          }
        }
      }
      &__icon {
        margin: 0 0 10px 0;
        display: flex;
        grid-gap: 2px;
        span {
          &:nth-child(1) {
            width: 70px;
            height: 30px;
            background: $medium-gray;
          }
          &:nth-child(2) {
            width: 20px;
            height: 30px;
            background: repeating-linear-gradient(
              0deg,
              transparent,
              transparent 2px,
              $medium-gray 2px,
              $medium-gray 4px
            );
          }
        }
      }
      &__label {
        font-size: 12px;
      }
    }
  }
}

.header-menu {
  @include desktopUp {
    display: none;
  }
  @include e('open-nav') {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 $small-gutter;
    cursor: pointer;
    background: $dark-gray;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include desktopUp {
      display: none;
    }
    @include e('line') {
      transition: $transition;
      background: white;
      width: 25px;
      height: 1px;
      margin: 2px 0;
      display: block;
    }
  }
  @include e('nav') {
    transition: all 500ms $easeOutExpo;
    position: fixed;
    top: $header-height;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.5);
    transform: translateX(-100%);
    @include e('items') {
      background: $lighter-gray;
      height: 100%;
      margin-right: 40px;
      padding-top: 25px;
      box-shadow: 3px 2px 5px 0px rgba(black, 0.1);
      overflow: auto;
    }
    @include e('item') {
      display: block;
      &:last-child {
        margin-bottom: 25px;
      }
      @include e('link') {
        transition: $transition;
        padding: 8px 30px;
        display: block;
        cursor: pointer;
        color: $medium-gray;
        font-size: 14px;
        &:hover {
          background: rgba(black, 0.07);
        }
      }
      @include m('active') {
        .header-menu__nav__item__link {
          background: rgba(black, 0.07);
        }
      }
    }
    @include desktopUp {
      position: relative;
      top: initial;
      left: initial;
      right: initial;
      bottom: initial;
      display: inline-flex;
      transform: translateX(0%);
      background: transparent;
    }
  }
  @include m('is-opened') {
    .header-menu__nav {
      transform: translateX(0%);
    }
    .header-menu__open-nav {
      .header-menu__open-nav__line {
        &:nth-child(2) {
          transform: rotate(45deg);
          margin: -1px 0;
          opacity: 0;
        }
        &:nth-child(1) {
          transform: rotate(45deg);
          margin: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          margin: 0 0;
        }
      }
    }
  }
}

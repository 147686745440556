@mixin e($element) {
  &__#{$element} {
    @content;
  }
}

@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

@mixin loader ($color: white) {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: rotateLoader 500ms linear infinite;
  border: 1px solid rgba($color, 1);
  border-bottom-color: rgba($color, 0);
}

@keyframes rotateLoader {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(359deg)
  }
}

@mixin tabletUp {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tabletOnly {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktopUp {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin smallOnly {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin smoothShadow ($percentage: 0.05, $color: black, $init: 16px) {
  box-shadow:
    0 $init $init rgba($color, $percentage),
    0 ($init*2) ($init*2) rgba($color, $percentage),
    0 ($init*2*2) ($init*2*2) rgba($color, $percentage),
    0 ($init*2*2*2) ($init*2*2*2) rgba($color, $percentage),
    0 ($init*2*2*2*2) ($init*2*2*2*2) rgba($color, $percentage);
}

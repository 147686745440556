.badge {
  display: inline-flex;
  padding: 2px 5px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  border-radius: 2px;
  @include m('success') {
    background: $green;
    color: $white;
  }
  @include m('danger') {
    background: $red;
    color: $white;
  }
  @include m('pending') {
    background: $gray;
    color: black;
  }
}

.icon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  fill: currentColor;
  @include m('small') {
    width: 14px;
    height: 14px;
  }
  @include m('large') {
    width: 48px;
    height: 48px;
  }
  @include m('primary') {
    fill: $primary;
  }
  @include m('danger') {
    fill: $danger;
  }
  @include m('warning') {
    fill: $warning;
  }
  @include m('info') {
    fill: $info;
  }
  @include m('success') {
    fill: $success;
  }
  span {
    display: flex;
    flex-grow: 1;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.project-relation-manager {
  margin: 0 0 20px 0;
  &__title {
    font-size: 12px;
    display: block;
    margin: 0 0 10px 0;
  }
  &__list {
    .project-relation-manager__item {
      margin-bottom: 20px;
      &::last-child {
        margin-bottom: 0;
      }
    }
  }
  &__item {
    display: flex;
    margin: 0 -5px;
    padding: 10px;
    background: $lighter-gray;
    &__group {
      display: flex;
      &__item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        &--small-last {
          margin-left: 10px;
          width: 30%;
          flex-grow: initial;
          flex-shrink: 0;
          flex-basis: initial;
        }
      }
    }
    &__control {
      margin: 0 5px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      .form__control {
        margin: 0;
      }
      .media-library {
        margin: 0;
      }
      &--actions {
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
      }
    }
    &__remove {
      cursor: pointer;
      color: $red;
    }
    &__drag-handle {
      cursor: pointer;
      font-size: 20px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &--error {
    .project-relation-manager__title {
      color: $red;
    }
  }
}

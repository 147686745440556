.media-list {
  .data-table__custom-list {
    display: grid;
    grid-gap: $small-gutter;
    @include tabletUp {
      grid-template-columns: repeat(3, 1fr);
    }
    @include desktopUp {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &--hide-captions {
    .media-item__caption {
      display: none;
    }
  }
}

.media-item {
  transition: $transition;
  border: 1px solid $gray;
  display: flex;
  flex-direction: column;
  background: $lighter-gray;
  position: relative;
  &--enabled {
    border: 1px solid $medium-gray;
    background: white;
    .media-item__image {
      opacity: 1;
    }
  }
  &--loading {
    pointer-events: none;
    .media-item__image {
      opacity: 0.2;
    }
  }
  &--winner {
    background: $yellow;
    border-color: black;
    .media-item__header__actions__winner-link {
      opacity: 1;
      .icon {
        color: black;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    line-height: 1;
    &__avatar {
      width: 36px;
      height: 36px;
      margin: 0 10px 0 0;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__username {
      flex-grow: 1;
      flex-shrink: 1;
      word-break: break-all;
      margin-right: 10px;
      line-height: 1.2;
      text-decoration: none;
    }
    &__actions {
      display: flex;
      &__winner-link {
        opacity: 0.2;
        margin-right: 10px;
      }
    }
  }
  &__image {
    transition: $transition;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    opacity: 0.3;
    img, video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__caption {
    padding: 20px 20px 0 20px;
    flex-grow: 1;
    word-break: break-word;
  }
  &__timestamp {
    padding: 10px 20px;
    font-size: 12px;
    opacity: 0.5;
  }
  .spinner {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.youtube-preview {
  cursor: pointer;
  &--opened {
    .youtube-preview__full {
      pointer-events: initial;
      opacity: 1;
      perspective: 200px;
    }
    .youtube-preview__full__youtube {
      animation: showImage 200ms 100ms forwards $easeOutQuad;
    }
  }
  &__youtube {
    transition: $transition;
    &:hover {
      background: #999;
    }
  }
  &__full {
    position: fixed;
    overflow: hideen;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    pointer-events: none;
    opacity: 0;
    transition: $transition;
    &__youtube {
      transition: $transition;
      max-width: 100%;
      max-height: 100%;
      opacity: 0;
    }
  }
}

@keyframes showImage {
  0% {
    transform: rotateX(5deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

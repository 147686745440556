.room-registrations {
  @include e('filters') {}
  @include e('list') {
    .room-registration {
      margin: 0 0 $small-gutter;
      &:last-child {
        margin: 0;
      }
    }
  }
  @include e('pagination') {}
}

.room-registration {
  border: 1px solid $gray;
  padding: 20px;
  display: flex;
  @include e('basic') {
    margin-right: 20px;
    text-align: center;
    @include e('date') {
      font-size: 14px;
      font-weight: $regular-weight;
      margin: 0 0 10px 0;
    }
    @include e('name') {
      font-size: 20px;
      font-weight: $bold-weight;
      margin: 0 0 10px 0;
    }
    @include e('phone') {
      margin: 0 0 5px 0;
      font-size: 12px;
    }
    @include e('email') {
      margin: 0 0 5px 0;
    }
    @include e('document') {
      font-size: 12px;
    }
  }
  @include e('info') {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    @include e('item') {
      background: $lighter-gray;
      padding: 10px;
      margin: 10px;
      @include e('title') {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: $bold-weight;
        margin: 0 0 10px 0;
      }
      @include e('value') {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}

.form-tips {
  @include e('content') {
    font-size: 12px;
    color: darken($gray, 15%);
    font-weight: $light-weight;
    p {
      margin: 0 0 15px 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
.form-and-tips {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @include tabletUp {
    flex-direction: row;
  }
  @include e('form') {
    @include tabletUp {
      width: 50%;
    }
  }
  @include e('tips') {
    margin-top: $small-gutter;
    @include tabletUp {
      width: 50%;
      margin-top: 0;
      margin-left: 50px;
      padding-left: 50px;
      border-left: 1px solid #ddd;
    }
  }
}

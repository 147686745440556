.project-media-row {
  &__label {
    font-size: 12px;
    margin: 0 0 5px 0;
    display: block;
  }
  &__options {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    margin: 0 0 10px 0;
    &__item {
      border: 1px solid transparent;
      cursor: pointer;
      padding: 5px;
      text-align: center;
      &--selected,
      &:hover {
        background: #ccc;
        border-color: $medium-gray;
      }
      &--variation-0 {
        .project-media-row__options__item__icon {
          grid-gap: 0;
        }
      }
      &--variation-1,
      &--variation-2 {
        .project-media-row__options__item__icon {
          grid-template-areas:
            "one two"
            "one three"
          ;
          span {
            &:nth-child(1) {
              width: 40px;
            }
            &:nth-child(2),
            &:nth-child(3) {
              height: 19px;
            }
          }
        }
      }
      &--variation-2 {
        .project-media-row__options__item__icon {
          grid-template-areas:
            "two one"
            "three one"
          ;
        }
      }
      &--variation-3 {
        .project-media-row__options__item__icon {
          grid-template-areas:
            "one two"
          ;
          span {
            &:nth-child(1),
            &:nth-child(2) {
              width: 30px;
              height: 40px;
            }
            &:nth-child(3) {
              display: none;
            }
          }
        }
      }
      &--variation-4 {
        .project-media-row__options__item__icon {
          grid-template-areas:
            "one two three"
          ;
          span {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              width: 20px;
              height: 40px;
            }
          }
        }
      }
      &--variation-5 {
        .project-media-row__options__item__icon {
          grid-template-areas:
            "one one two"
          ;
          span {
            &:nth-child(1),
            &:nth-child(2) {
              width: 20px;
              height: 40px;
            }
            &:nth-child(1) {
              width: 40px;
            }
            &:nth-child(3) {
              display: none;
            }
          }
        }
      }
      &--variation-6 {
        .project-media-row__options__item__icon {
          grid-template-areas:
            "one two two"
          ;
          span {
            &:nth-child(1),
            &:nth-child(2) {
              width: 20px;
              height: 40px;
            }
            &:nth-child(2) {
              width: 40px;
            }
            &:nth-child(3) {
              display: none;
            }
          }
        }
      }
      &__icon {
        display: grid;
        grid-gap: 2px;
        grid-template-areas:
          "one two three"
        ;
        span {
          width: 20px;
          height: 40px;
          background: $medium-gray;
          &:nth-child(1) {
            grid-area: one;
          }
          &:nth-child(2) {
            grid-area: two;
          }
          &:nth-child(3) {
            grid-area: three;
          }
        }
      }
    }
  }
  &__columns {
    background: #ddd;
    padding: 10px;
  }
  &__small-items {
    display: none;
  }
  &__column {
    margin: 0 0 15px 0;
    border: 1px solid $gray;
    padding: 10px;
    &:last-child {
      margin: 0;
    }
    &__options {
      margin: 0 0 10px 0;
    }
    &__data {
      &__control {
        display: flex;
        align-items: center;
        &__comp {
          flex-grow: 1;
          flex-shrink: 0;
        }
        &__image {
          flex-grow: 0;
          flex-shrink: 0;
          margin-left: 10px;
          width: 70px;
          height: auto;
          min-height: auto;
        }
      }
    }
  }
  &--small {
    display: flex;
    align-items: center;
    .project-media-row__label {
      display: none;
    }
    .project-media-row__options {
      margin: 0;
    }
    .project-media-row__options__item:not(.project-media-row__options__item--selected) {
      display: none;
    }
    .project-media-row__columns {
      display: none;
    }
    .project-media-row__small-items {
      display: flex;
      align-items: center;
    }
  }
}

.slug-comp {
  display: flex;
  align-items: center;
  .input-wrapper {
    flex-grow: 1;
    margin-right: 10px;
  }
  &__auto {
    &__label {
      cursor: pointer;
      display: flex;
      align-items: center;
      > span  {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}

.sort-changer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include e('button') {
    cursor: pointer;
    display: inline-block;
    background: $dark-gray;
    color: white;
    margin: 1px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    @include m('disabled') {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
